import { router } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useRef } from 'react';
import { Search, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { SharedProps } from '../types';
import { Button } from './Button';
import { Icon } from './Icon';
import { Input } from './Input';
import { InputWrapper } from './InputWrapper';

interface SearchInputProps {
  /**
   * If a name is given, it will be used to prefix the URL param.
   * Use if multiple search inputs are on the same page.
   */
  name?: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

export function SearchInput({
  name,
  id,
  placeholder,
  autoFocus,
  onChange,
}: SearchInputProps) {
  const { t } = useTranslation();
  const { params } = usePage<SharedProps>().props;
  const searchParam = name ? `${name}_search` : 'search';
  const pageParam = name ? `${name}_page` : 'page';
  const value = params[searchParam] as string || '';

  const inputRef = useRef<HTMLInputElement | null>(null);
  const timerRef = useRef<number>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    window.clearTimeout(timerRef.current);

    timerRef.current = window.setTimeout(() => {
      const value = event.target.value;

      router.get(
        '',
        { [searchParam]: value, [pageParam]: undefined },
        { preserveScroll: true, preserveState: true, replace: true },
      );

      onChange?.(value);
    }, 300);
  };

  useEffect(() => () => window.clearTimeout(timerRef.current), []);

  const handleReset = () => {
    router.get(
      '',
      { [searchParam]: undefined, [pageParam]: undefined },
      { preserveScroll: true, preserveState: true, replace: true },
    );

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <InputWrapper>
      <label htmlFor={id}>
        <Icon className={classNames(value ? 'text-slate-800' : 'text-slate-400')}>
          <Search />
        </Icon>
      </label>
      <Input
        aria-label={t('shared:filter.search')}
        role="searchbox"
        defaultValue={value || ''}
        onChange={handleChange}
        className="pl-9"
        id={id}
        autoFocus={autoFocus}
        placeholder={placeholder || t('shared:filter.search')}
        ref={inputRef}
      />
      {value && (
        <Button
          onClick={handleReset}
          variant="tertiary"
          className="relative text-slate-400 hover:text-slate-800 !border-transparent"
          aria-label={t('shared:filter.clear')}
        >
          <Icon>
            <XCircle />
          </Icon>
        </Button>
      )}
    </InputWrapper>
  );
}
